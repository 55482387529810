import React, { useEffect, useState } from "react";
import { getUserLocation } from "../../../api/userAddresses";

function UserLocation({ user_phone }) {
  const [latitude, setLatitude] = useState(" ");
  const [longitude, setLongitude] = useState(" ");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        user_phone = "+977-9849151423";
        const data = await getUserLocation({ user_phone });
        setLatitude(data.data.latitude);
        setLongitude(data.data.longitude);
      } catch (error) {
        setError(error);
      }
    };
    fetchLocation();
  }, [user_phone]);

  return (
    <>
      <div>Latitude: {latitude ? latitude : <span>N/A</span>}</div>
      <div style={{ marginBottom: "10px" }}>
        Longitude: {longitude ? longitude : <span>N/A</span>}
      </div>
    </>
  );
}

export default UserLocation;
