import { Table, Space } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Spin, Button } from "antd";
import { isEmpty, uniqBy } from "lodash";
import { exportUserList, exportAllUsers } from "../../../api/users";
import * as excel from "exceljs";
import { saveAs } from "file-saver";

const UserList = () => {
  let navigate = useNavigate();

  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);

  const [checkedRows, setCheckedRows] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllUsers, setIsAllUsers] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);

  const [sortObj, setSortObj] = useState({
    sortType: {
      id: true,
      full_name: true,
    },
    sort: [],
  });

  let timeout = 0;

  const { data, refetch, isRefetching, status } = useQuery({
    queryKey: [
      "get-users",
      page.toString() + pageSize.toString(),
      sortObj.sort,
    ],
    queryFn: () => exportUserList(page, pageSize, sortObj.sort),
  });

  const {
    data: allUsers,
    status: loadAllUsers,
    refetch: refetchAllUsers,
  } = useQuery({
    queryKey: ["get-all-users"],
    queryFn: () => exportAllUsers(setLoadedCount),
  });

  useEffect(() => {
    if (data && status === "success" && !isRefetching) {
      setUsers([]);
      setUsers((prev) => uniqBy([...prev, ...data.results], "username"));
    }
  }, [data, status, isRefetching]);

  useEffect(() => {
    refetch();
  }, [page, sortObj, pageSize]);

  useEffect(() => {
    if (isAllUsers && loadAllUsers === "loading") {
      setIsLoading(true);
    }
    if (isAllUsers && allUsers && loadAllUsers === "success") {
      exportToExcel(allUsers);
      setIsAllUsers(false);
      setIsLoading(false);
    }
  }, [allUsers, isAllUsers, loadAllUsers]);
  // console.log("export user data", users);
  const sorting = (header, name) =>
    setSortObj({
      sortType: {
        ...sortObj.sortType,
        [name]: !sortObj.sortType[name],
      },
      sort: [`${sortObj.sortType[name] ? "" : "-"}${header.dataIndex}`],
    });

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      width: "15%",
      render: (text, record) => {
        return (
          <span className="w-16" style={{ overflowWrap: "anywhere" }}>
            {text}
          </span>
        );
      },
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sorting(header, "username"),
        };
      },
    },
    {
      title: "Shop Name",
      dataIndex: "shop_name",
      width: "15%",
      render: (_, { shop }) => (
        <span className="w-20" style={{ overflowWrap: "anywhere" }}>
          {shop}
        </span>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "15%",
      render: (_, { address }) => (
        <span className="w-20" style={{ overflowWrap: "anywhere" }}>
          {address}
        </span>
      ),
    },
    {
      title: "PAN NO.",
      dataIndex: "pan_no",
      width: "10%",
      render: (_, { pan_no }) => (
        <span className="w-20" style={{ overflowWrap: "anywhere" }}>
          {pan_no}
        </span>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: "7%",
      render: (_, { is_active }) => <span>{is_active ? "Yes" : "No"}</span>,
    },
    {
      title: "No of Order",
      children: [
        {
          title: "Last Month",
          dataIndex: "no_of_order_last_month",
          key: "no_of_order_last_month",
        },
        {
          title: "Last 3 months",
          dataIndex: "no_of_order_last_3_month",
          key: "no_of_order_last_3_month",
        },
        {
          title: "Last 6 months",
          dataIndex: "no_of_order_last_6_month",
          key: "no_of_order_last_6_month",
        },
        {
          title: "Last Year",
          dataIndex: "no_of_order_last_year",
          key: "no_of_order_last_year",
        },
        {
          title: "Lifetime",
          dataIndex: "no_of_order_lifetime",
          key: "no_of_order_lifetime",
        },
      ],
    },
    {
      title: "Total Order Value ",
      children: [
        {
          title: "Last Month",
          dataIndex: "total_order_value_last_month",
          key: "total_order_value_last_month",
        },
        {
          title: "Last 3 months",
          dataIndex: "total_order_value_last_3_month",
          key: "total_order_value_last_3_month",
        },
        {
          title: "Last 6 months",
          dataIndex: "total_order_value_last_6_month",
          key: "total_order_value_last_6_month",
        },
        {
          title: "Last Year",
          dataIndex: "total_order_value_last_year",
          key: "total_order_value_last_year",
        },
        {
          title: "Lifetime",
          dataIndex: "total_order_value_lifetime",
          key: "total_order_value_lifetime",
        },
      ],
    },
  ];

  const rowSelection = {
    selectedRowKeys: checkedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedRows(selectedRowKeys);
      setSelectedUsers(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
  };

  const exportToExcel = (usersToExport) => {
    setIsLoading(true);
    const workbook = new excel.Workbook();
    const worksheet = workbook.addWorksheet("Users");

    worksheet.mergeCells("A1:A2");
    worksheet.getCell("A1").value = "User Name";
    worksheet.mergeCells("B1:B2");
    worksheet.getCell("B1").value = "Shop Name";
    worksheet.mergeCells("C1:C2");
    worksheet.getCell("C1").value = "Address";
    worksheet.mergeCells("D1:D2");
    worksheet.getCell("D1").value = "PAN NO.";
    worksheet.mergeCells("E1:E2");
    worksheet.getCell("E1").value = "Is Active?";
    worksheet.mergeCells("F1:J1");
    worksheet.getCell("F1").value = "No of Order";
    worksheet.getCell("F2").value = "Last Month";
    worksheet.getCell("G2").value = "Last 3 months";
    worksheet.getCell("H2").value = "Last 6 months";
    worksheet.getCell("I2").value = "Last Year";
    worksheet.getCell("J2").value = "Lifetime";
    worksheet.mergeCells("K1:O1");
    worksheet.getCell("K1").value = "Total Order Value";
    worksheet.getCell("K2").value = "Last Month";
    worksheet.getCell("L2").value = "Last 3 months";
    worksheet.getCell("M2").value = "Last 6 months";
    worksheet.getCell("N2").value = "Last Year";
    worksheet.getCell("O2").value = "Lifetime";

    const headerCells = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "F2",
      "G2",
      "H2",
      "I2",
      "J2",
      "K1",
      "K2",
      "L2",
      "M2",
      "N2",
      "O2",
    ];
    headerCells.forEach((cell) => {
      worksheet.getCell(cell).font = {
        bold: true,
      };
      // worksheet.getCell(cell).fill = {
      //   type: "pattern",
      //   pattern: "solid",
      //   // fgColor: { argb: "01A0B0" },
      // };
      worksheet.getCell(cell).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    });

    // Set column widths
    worksheet.columns = [
      { key: "username", width: 15 },
      { key: "shop_name", width: 20 },
      { key: "address", width: 25 },
      { key: "pan_no", width: 10 },
      { key: "is_active", width: 10 },
      { key: "no_of_order_last_month", width: 15 },
      { key: "no_of_order_last_3_month", width: 15 },
      { key: "no_of_order_last_6_month", width: 15 },
      { key: "no_of_order_last_year", width: 15 },
      { key: "no_of_order_lifetime", width: 15 },
      { key: "total_order_value_last_month", width: 20 },
      { key: "total_order_value_last_3_month", width: 20 },
      { key: "total_order_value_last_6_month", width: 20 },
      { key: "total_order_value_last_year", width: 20 },
      { key: "total_order_value_lifetime", width: 20 },
    ];

    // Add the data
    usersToExport.forEach((user, index) => {
      const rowIndex = index + 3;
      worksheet.getCell(`A${rowIndex}`).value = user.username;
      worksheet.getCell(`B${rowIndex}`).value = user.shop_name;
      worksheet.getCell(`C${rowIndex}`).value = user.address;
      worksheet.getCell(`D${rowIndex}`).value = user.pan_no;
      worksheet.getCell(`E${rowIndex}`).value = user.is_active ? "Yes" : "No";
      worksheet.getCell(`F${rowIndex}`).value = user.no_of_order_last_month;
      worksheet.getCell(`G${rowIndex}`).value = user.no_of_order_last_3_month;
      worksheet.getCell(`H${rowIndex}`).value = user.no_of_order_last_6_month;
      worksheet.getCell(`I${rowIndex}`).value = user.no_of_order_last_year;
      worksheet.getCell(`J${rowIndex}`).value = user.no_of_order_lifetime;
      worksheet.getCell(`K${rowIndex}`).value =
        user.total_order_value_last_month;
      worksheet.getCell(`L${rowIndex}`).value =
        user.total_order_value_last_3_month;
      worksheet.getCell(`M${rowIndex}`).value =
        user.total_order_value_last_6_month;
      worksheet.getCell(`N${rowIndex}`).value =
        user.total_order_value_last_year;
      worksheet.getCell(`O${rowIndex}`).value = user.total_order_value_lifetime;
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "selected_users.xlsx");
      setIsLoading(false);
    });
  };

  // console.log("checked Rows", checkedRows);
  console.log("selected users", selectedUsers);
  return (
    <>
      {(status === "loading" || isRefetching || isLoading) && <Spin />}
      {isAllUsers && <div> Loaded {loadedCount} users ...</div>}
      <div className="mb-2 flex gap-2 justify-end sm: flex-row flex-col">
        <Space className="justify-end">
          {!isEmpty(checkedRows) && (
            <Button
              className="bg-cyan-500 text-white"
              type="default"
              onClick={() => exportToExcel(selectedUsers)}
            >
              Export Selected
            </Button>
          )}

          <Button
            className="bg-cyan-500 text-white"
            type="default"
            onClick={() => {
              setIsAllUsers(true);
            }}
          >
            <Space>Export All</Space>
          </Button>
        </Space>
      </div>

      {}

      {data && (
        <Table
          columns={columns}
          dataSource={users?.map((item) => ({ ...item, key: item.username }))}
          loading={status === "loading"}
          pagination={{
            showSizeChanger: true,
            pageSize,
            total: data?.count,
            current: page,

            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
          rowSelection={{ ...rowSelection }}
          scroll={{ x: !isEmpty(users) && 1000 }}
          showSorterTooltip={false}
        />
      )}
    </>
  );
};

export default UserList;
