import { Tabs } from "antd";
import UserList from "./UserList";
import CustomPageHeader from "../../../shared/PageHeader";

const CustomerRecord = () => {
  return (
    <div>
      <CustomPageHeader title="Customer Record" isBasicHeader />
      <Tabs className="bg-white !p-6 rounded-lg" defaultActiveKey="general">
        <Tabs.TabPane key="general" tab="Customer">
          <UserList />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default CustomerRecord;
