import { useState, useContext } from "react";
import {
  Table,
  Tag,
  Button,
  Select,
  DatePicker,
  Input,
  Menu,
  Dropdown,
  Space,
  Modal,
  Form,
} from "antd";
import { useMutation } from "react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaRegFileArchive } from "react-icons/fa";
import getOrderStatusColor from "../../shared/tagColor";
import DeleteOrder from "./components/DeleteOrder";
import ProductSKUModal from "./components/ProductSKUModal";
import ButtonWPermission from "../../shared/ButtonWPermission";
import { isEmpty, capitalize, uniqBy } from "lodash";
import { DELIVERY_STATUS, IN_PROCESS, CLV_TIME_RANGES } from "../../constants";
import { archiveBulkOrders } from "../../api/orders";
import { updateOrderStatus } from "../../api/orders";
import { postFilters, deleteFilter } from "../../api/filter";
import { OrderContext } from ".";
import { useAuth } from "../../AuthProvider";
import { openErrorNotification, openSuccessNotification } from "../../utils";
import { MdDelete } from "react-icons/md";
import * as excel from "exceljs";
import { saveAs } from "file-saver";

const FilterDropdownItem = ({ filter, onDelete }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <span>{filter.filter_name}</span>
    <Button
      type="text"
      icon={<MdDelete />}
      onClick={(e) => {
        e.stopPropagation(); // Prevent Select from triggering
        onDelete(filter.id);
      }}
    />
  </div>
);
const OrdersList = () => {
  const {
    dataSource,
    status,
    refetchOrders,
    page,
    setPage,
    pageSize,
    setPageSize,
    userSearch,
    shopSearch,
    ordersCount,
    sortObj,
    setSortObj,
    orderStatus,
    setOrderStatus,
    provinces,
    setProvinces,
    cities,
    areas,
    cityList,
    areaList,
    provinceList,
    setCities,
    setAreas,
    categories,
    allCategories,
    setCategories,
    brands,
    allBrands,
    setBrands,
    products,
    allProducts,
    setProducts,
    timerange,
    setTimeRange,
    dateRange,
    setDateRange,
    filterName,
    setFilterName,
    saveFilterOpen,
    setSaveFilterOpen,
    filterState,
    allFilters,
    refetchFilters,
    selectedFilter,
    setSelectedFilter,
  } = useContext(OrderContext);

  const { isMobileView } = useAuth();

  const { RangePicker } = DatePicker;

  let timeout = 0;

  const [isArchiveOrder, setIsArchiveOrder] = useState({
    isOpen: false,
    id: null,
  });

  const [isBulkArchiveOrderOpen, setIsBulkArchiveOrderOpen] = useState(false);

  const [checkedRows, setCheckedRows] = useState([]);

  const [productSkuPopup, setProductSkuPopup] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState([]);

  const navigate = useNavigate();

  const sortingFn = (header, name) =>
    setSortObj({
      sortType: {
        ...sortObj.sortType,
        [name]: !sortObj.sortType[name],
      },
      sort: [
        `${sortObj.sortType[name] ? "" : "-"}${
          header.dataIndex === "id" ? "created_at" : header.dataIndex
        }`,
      ],
    });

  const { mutate: saveFilterMutate, isLoading: isSaving } = useMutation(
    postFilters,
    {
      onSuccess: (data) => {
        openSuccessNotification("Filter saved successfully");
        refetchFilters();
        setSaveFilterOpen(false); // Close the modal after success
      },
      onError: (error) => {
        openErrorNotification("Failed to save filter");
      },
    }
  );

  const { mutate: deleteFilterMutate, isLoading: isDeleting } = useMutation(
    deleteFilter,
    {
      onSuccess: (data) => {
        openSuccessNotification("Filter deleted successfully");
        refetchFilters();
        if (selectedFilter.filter_name === data.filter_name) {
          clearFilters();
        }
      },
      onError: (error) => {
        openErrorNotification("Failed to delete filter");
      },
    }
  );

  const handleDeleteFilter = (id) => {
    deleteFilterMutate(id);
  };

  const handleSaveFilter = () => {
    console.log("Saving Filter State as", filterState);
    saveFilterMutate(filterState);
  };

  const handleFilterChange = (val) => {
    setSelectedFilter(val);

    const selectedFilterData = allFilters.find((filter) => filter.id === val);
    if (selectedFilterData) {
      loadFilterData(selectedFilterData);
    } else {
      clearFilters();
    }
  };

  const loadFilterData = (data) => {
    setFilterName(data.filter_name || "");
    setCategories(data.filter_data.categories || []);
    setBrands(data.filter_data.brands || []);
    setCities(data.filter_data.cities || []);
    setAreas(data.filter_data.areas || []);
    setOrderStatus(data.filter_data.status || []);
    setProvinces(data.filter_data.provinces || []);
    setProducts(data.filter_data.products || []);
    setTimeRange(data.filter_data.time_range || "");
    userSearch.current = data.filter_data.username;
    shopSearch.current = data.filter_data.shop;
    const startDate = data.filter_data.start_date
      ? moment(data.filter_data.start_date)
      : null;
    const endDate = data.filter_data.end_date
      ? moment(data.filter_data.end_date)
      : null;
    setDateRange(startDate && endDate ? [startDate, endDate] : []);
  };

  const clearFilters = () => {
    setSelectedFilter(null);
    setFilterName("");
    setCategories([]);
    setBrands([]);
    setCities([]);
    setAreas([]);
    setOrderStatus([]);
    setProvinces([]);
    setProducts([]);
    setTimeRange("");
    setDateRange([]);
    shopSearch.current = null;
    userSearch.current = null;
  };

  const downloadTable = async () => {
    const workbook = new excel.Workbook();
    const worksheet = workbook.addWorksheet("Orders");

    worksheet.columns = columns
      .filter((col) => col.key !== "action")
      .map((col) => ({
        header: col.title,
        key: col.key,
      }));

    dataSource.forEach((order) => {
      const row = {};
      columns.forEach((col) => {
        if (col.dataIndex) {
          row[col.key] = order[col.dataIndex];
        }
      });

      worksheet.addRow(row);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "orders.xlsx");
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "orderId",
      render: (_, { id, status }) => {
        return (
          <div
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => navigate(`/orders/view-order/${id}`)}
          >
            #{id}
          </div>
        );
      },
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "created_at"),
        };
      },
    },
    {
      title: "Customer",
      dataIndex: "user",
      key: "user",
      width: "20%",
      render: (_, { user, id }) => {
        return (
          <div
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => navigate(`/orders/view-order/${id}`)}
          >
            {` ${user}`}
          </div>
        );
      },
    },
    {
      title: "Total Paid Amount",
      dataIndex: "total_paid_amount",
      key: "total_paid_amount",
      render: (_, { total_paid_amount }) => {
        return <>Rs. {total_paid_amount}</>;
      },
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "total_paid_amount"),
        };
      },
    },
    {
      title: "Delivery Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return (
          <>
            <Tag color={getOrderStatusColor(status)}>
              {status.toUpperCase().replaceAll("_", " ")}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Shop Name",
      dataIndex: "shop_name",
      key: "shop_name",
      width: "13%",
      render: (_, { shop_name }) => (
        <span className="w-16" style={{ overflowWrap: "anywhere" }}>
          {capitalize(shop_name?.replaceAll("_", " "))}
        </span>
      ),
    },
    {
      title: "Preferred Delivery Time",
      dataIndex: "preferable_delivery_time",
      key: "preferable_delivery_time",
      render: (preferable_delivery_time) => {
        return (
          <span className="flex gap-0.5">
            {preferable_delivery_time
              ? capitalize(preferable_delivery_time)
              : "_"}
          </span>
        );
      },
    },
    {
      title: "Order Confirmation Call",
      dataIndex: "order_confirmation",
      key: "order_confirmation",
      render: (order_confirmation) => {
        return (
          <span className="flex gap-0.5">
            {order_confirmation ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      title: "Payment Method",
      dataIndex: "payment",
      key: "payment_method",
      render: (payment) => {
        return (
          <span className="flex gap-0.5">
            {payment?.payment_method
              ? capitalize(payment.payment_method.replaceAll("_", " "))
              : ""}
          </span>
        );
      },
    },
    {
      title: "Ordered At",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => {
        return <>{moment(created_at).format("lll")}</>;
      },
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "created_at"),
        };
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_, { id }) => {
        return (
          <>
            <ButtonWPermission
              className="!border-none !bg-inherit"
              codename="delete_order"
              icon={<FaRegFileArchive />}
              onClick={() => setIsArchiveOrder({ isOpen: true, id })}
            />
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: checkedRows,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
      {
        key: "without_progress",
        text: " Select without progress",

        onSelect: (rowKeys) => {
          setCheckedRows(
            rowKeys.filter(
              (key) =>
                dataSource?.find((item) => item.id === key)?.status !==
                IN_PROCESS
            )
          );
        },
      },
    ],

    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedRows((prev) =>
        uniqBy([...selectedRows.map((item) => item.id), ...prev])
      );
      setSelectedOrders((prev) => uniqBy([...selectedRows, ...prev], "id"));
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
  };

  const handleArchiveOrder = useMutation(
    (id) =>
      updateOrderStatus({
        orderId: id,
        status: "archived",
      }),
    {
      onSuccess: (data) => {
        openSuccessNotification(data.message);
        refetchOrders();
        setIsArchiveOrder({ isOpen: false, id: null });
      },

      onError: (error) => {
        openErrorNotification(error);
      },
    }
  );

  const handleArchiveBulkOrders = useMutation((ids) => archiveBulkOrders(ids), {
    onSuccess: (res) => {
      openSuccessNotification(res.message);
      refetchOrders();
      setCheckedRows([]);
      setIsBulkArchiveOrderOpen(false);
    },
    onError: (err) => openErrorNotification(err),
  });

  return (
    <div className="hidden sm:block">
      <div className="mb-2 flex gap-2 justify-between sm:flex-row flex-col ">
        <div className="flex gap-2 flex-wrap">
          <Input
            className="!w-72"
            placeholder="Search by Contact"
            value={userSearch.current ? userSearch.current : undefined}
            onChange={(e) => {
              userSearch.current = e.target.value;
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                setPage(1);
                refetchOrders();
              }, 400);
            }}
          />
          <Input
            className="!w-72"
            placeholder="Search Shop Name"
            value={shopSearch.current ? shopSearch.current : undefined}
            onChange={(e) => {
              shopSearch.current = e.target.value;
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                setPage(1);
                refetchOrders();
              }, 400);
            }}
          />

          <Select
            className="!w-72"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            optionFilterProp="children"
            options={allProducts?.map((product) => ({
              value: product.name,
              label: product.name,
            }))}
            placeholder="Search Products"
            style={{ width: 100 }}
            showSearch
            onChange={(val) => setProducts(val)}
            value={products && products.length > 0 ? products : undefined}
          />
          <Select
            className="!w-60"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            optionFilterProp="children"
            options={allCategories?.map((category) => ({
              value: category.name,
              label: category.name,
            }))}
            placeholder="Search by Categories"
            style={{ width: 100 }}
            showSearch
            onChange={(val) => setCategories(val)}
            value={categories && categories.length > 0 ? categories : undefined}
          />
          <Select
            className="!w-60"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            optionFilterProp="children"
            options={allBrands?.map((brand) => ({
              value: brand.name,
              // label: brand.name,
            }))}
            value={brands && brands.length > 0 ? brands : undefined}
            placeholder="Search by Brands"
            style={{ width: 100 }}
            showSearch
            onChange={(val) => setBrands(val)}
          />
          <Select
            className="!w-60"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            optionFilterProp="children"
            options={provinceList?.map((province) => ({
              value: province,
              label: province,
            }))}
            placeholder="Search by Province"
            style={{ width: 100 }}
            showSearch
            onChange={(val) => setProvinces(val)}
            value={provinces && provinces.length > 0 ? provinces : undefined}
          />
          <Select
            className="!w-60"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            optionFilterProp="children"
            options={cityList?.map((city) => ({
              value: city,
              label: city,
            }))}
            placeholder="Search by Cities"
            style={{ width: 100 }}
            showSearch
            onChange={(val) => setCities(val)}
            value={cities && cities.length > 0 ? cities : undefined}
          />
          <Select
            className="!w-60"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            optionFilterProp="children"
            options={areaList?.map((area) => ({
              value: area,
              label: area,
            }))}
            placeholder="Search by Areas"
            style={{ width: 100 }}
            showSearch
            onChange={(val) => setAreas(val)}
            value={areas && areas.length > 0 ? areas : undefined}
          />
        </div>
      </div>
      <div className="mb-2 flex  gap-2 sm:flex-row flex-col">
        <RangePicker
          className="w-60"
          picker="date"
          defaultValue={dateRange}
          value={dateRange && dateRange.length > 0 ? dateRange : undefined}
          needConfirm={true}
          onChange={(val) => {
            // setStartDate(val[0].format("YYYY-MM-DD"));
            // setEndDate(val[1].format("YYYY-MM-DD"));
            setDateRange(val);
          }}
        />
        <Select
          className="!w-60"
          optionFilterProp="children"
          allowClear
          options={CLV_TIME_RANGES}
          placeholder="Time Ranges"
          style={{ width: 100 }}
          onChange={(val) => setTimeRange(val)}
          value={timerange && timerange.length > 0 ? timerange : undefined}
        />
        <Select
          className="!w-60"
          mode="multiple"
          optionFilterProp="children"
          options={DELIVERY_STATUS?.map((status) => ({
            value: status.id,
            label: status.name,
          }))}
          placeholder="Order Status"
          style={{ width: 100 }}
          onChange={(val) => setOrderStatus(val)}
          value={
            orderStatus && orderStatus.length > 0 ? orderStatus : undefined
          }
        />
        <Select
          className="!w-60"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          optionFilterProp="children"
          options={allFilters?.map((filter) => ({
            value: filter.id,
            label: (
              <FilterDropdownItem
                filter={filter}
                onDelete={handleDeleteFilter}
              />
            ),
          }))}
          placeholder="Load Filter"
          style={{ width: 100 }}
          showSearch
          onChange={handleFilterChange}
          allowClear
        />
        <Button
          onClick={() => {
            setSaveFilterOpen(true);
          }}
        >
          Save Filter
        </Button>
        <Modal
          title="Save Filter"
          open={saveFilterOpen}
          onCancel={() => {
            setSaveFilterOpen(false);
          }}
          footer={[
            <Button key="save" type="primary" onClick={handleSaveFilter}>
              Save
            </Button>,
          ]}
        >
          <Input
            placeholder="Enter Filter Name"
            value={filterName}
            onChange={(e) => {
              setFilterName(e.target.value);
            }}
          />
        </Modal>
      </div>

      <div className="mb-2 flex justify-end gap-2 sm:flex-row flex-col">
        <Button onClick={downloadTable}>Download Table</Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource?.map((item) => ({ ...item, key: item.id }))}
        loading={status === "loading"}
        pagination={{
          showSizeChanger: true,
          pageSize,
          total: ordersCount,
          current: page,

          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
        scroll={{ x: isEmpty(dataSource) && !isMobileView ? null : 1000 }}
        showSorterTooltip={false}
        size="small"
      />

      <DeleteOrder
        closeModal={() => setIsArchiveOrder({ isOpen: false, id: null })}
        deleteMutation={() => handleArchiveOrder.mutate(isArchiveOrder.id)}
        isOpen={isArchiveOrder.isOpen}
        status={handleArchiveOrder.status}
        title={"Order #" + isArchiveOrder.id}
      />

      <DeleteOrder
        closeModal={() => setIsBulkArchiveOrderOpen(false)}
        deleteMutation={() => handleArchiveBulkOrders.mutate(checkedRows)}
        isOpen={isBulkArchiveOrderOpen}
        status={handleArchiveBulkOrders.status}
        title="Archive Selected Order?"
      />
    </div>
  );
};

export default OrdersList;
