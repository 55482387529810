import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";

const HistoryPage = (props) => {
  const { data, name, type } = props;

  const columns = [
    {
      title: "Date",
      dataIndex: "timestamp",
      render: (timestamp) => moment(timestamp).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(),
    },
    {
      title: "New Price",
      dataIndex: "new_price",
    },
    {
      title: "Old Price",
      dataIndex: "old_price",
    },
    {
      title: "CH%",
      key: "change",
      render: (_, { new_price, old_price }) => {
        const change = ((new_price - old_price) / new_price) * 100;
        return <div> {change.toFixed(2)}%</div>;
      },
    },
  ];
  return (
    <div>
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={props.show}
        onCancel={() => {
          props.onClose();
        }}
        footer={null}
        centered={true}
      >
        <div className="mb-2 font-bold">{name}</div>
        <span className="mb-3 text-xs">{type}</span>
        <Table
          columns={columns}
          dataSource={data?.map((item) => ({ ...item, key: item.id })) || []}
          bordered
          pagination={false}
          size="small"
          scroll={{ y: 300 }}
          showSorterTooltip={false}
        />
      </Modal>
    </div>
  );
};

export default HistoryPage;
