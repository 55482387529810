import axios from "../axios";

export const getUsers = async (
  page,
  search,
  pageSize,
  sort,
  {
    province,
    city,
    area,
    max_order_amount,
    min_order_amount,
    order_date,
    order_number,
  } = {}
) => {
  const res = await axios.get(
    `/api/profile/admin/user-list/?page=${page || 1}&search=${
      search || ""
    }&size=${pageSize || 20}&sort=${sort || []}&province=${
      province || ""
    }&area=${area || ""}&city=${city || ""}&max_order_amount=${
      max_order_amount || ""
    }&min_order_amount=${min_order_amount || ""}&order_date=${
      order_date || ""
    }&order_number=${order_number || ""}`
  );

  return res.data.data;
};

export const exportAllUsers = async (updateLoadedCount) => {
  const res = await axios.get("/api/crm/export-user/?page=1&size=100");

  let [nextUrl, page] = [res.data.data.next, 2];
  const allResData = [...res.data.data.results];

  updateLoadedCount(allResData.length);

  while (nextUrl !== null) {
    const res = await axios.get(`/api/crm/export-user/?page=${page}&size=100`);
    nextUrl = res.data.data.next;
    allResData.push(...res.data.data.results);
    page += 1;
    updateLoadedCount(allResData.length);
  }

  if (allResData) return allResData;

  return res.data.data.results;
};

export const exportUserList = async (page, pageSize, sort) => {
  const res = await axios.get(
    `/api/crm/export-user/?page=${page || 1}&size=${pageSize || 20}&sort=${
      sort || []
    }`
  );

  return res.data.data;
};

export const getUserFilter = async (
  page,
  search,
  pageSize,
  sort,
  {
    province,
    city,
    area,
    max_order_amount,
    min_order_amount,
    order_date,
    order_number,
    active_at,
    login_at,
  } = {}
) => {
  const res = await axios.get(
    `/api/profile/admin/user-filter/?page=${page || 1}&search=${
      search || ""
    }&size=${pageSize || 20}&sort=${sort || []}&province=${
      province || ""
    }&area=${area || ""}&city=${city || ""}&max_order_amount=${
      max_order_amount || ""
    }&min_order_amount=${min_order_amount || ""}&order_date=${
      order_date || ""
    }&order_number=${order_number || ""}&active_at=${
      active_at || ""
    }&login_at=${login_at || ""}`
  );

  return res.data.data;
};

export const getEndUser = async () => {
  const response = await axios.get("/api/profile/");
  return response.data.data;
};

export const createUser = async (data) => {
  const response = await axios.post("/api/auth/create-user/admin/", data);
  return response.data;
};

export const createBulkUser = async (data) => {
  Promise.all(
    data.forEach(async (user) => {
      if (!user["VAT NO"]) delete user["VAT NO"];

      await axios.post(
        "/api/auth/create-user/admin/",
        (() => {
          if (user["VAT NO"])
            return {
              full_name: user["PARTY NAME"],
              phone: `+977-${user["MOBILE NO"]}`,
              shop_name: user["PARTY NAME"],
              pan_vat_number: user["VAT NO"],
            };
          else
            return {
              full_name: user["PARTY NAME"],
              phone: `+977-${user["MOBILE NO"]}`,
              shop_name: user["PARTY NAME"],
            };
        })()
      );
    })
  );
};

export const getAdminUsers = async (groupIds, page, pageSize, search, sort) => {
  const res = await axios.get(
    `/api/profile/admin/user-list/?group_ids=${groupIds}&page=${page}&size=${
      pageSize || 20
    }&search=${search || ""}&sort=${sort || []}`
  );

  return res.data.data;
};
