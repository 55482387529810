import axios from "../axios";

export const postFilters = async (data) => {
  const res = await axios.post(`/api/crm/filter-save/`, data);
  return res.data;
};

export const getFilters = async (data) => {
  const res = await axios.get(`/api/crm/filter-save/`);

  return res.data.data;
};

export const deleteFilter = async (id) => {
  const res = await axios.delete(`/api/crm/delete-filter/${id}`);

  return res.data.data;
};
