import { Tabs } from "antd";
import { uniqBy } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { createContext } from "react";
import { useMutation, useQuery } from "react-query";
import { getAddressRoute, getAddresses } from "../../api/userAddresses";
import { getPaginatedOrders, getfilteredorders } from "../../api/orders";
import { getAllCategories } from "../../api/categories";
import { getAllBrands } from "../../api/brands";
import { getAllProducts } from "../../api/products";
import { useAuth } from "../../AuthProvider";
import { getFilters } from "../../api/filter";
import { DELIVERY_STATUS } from "../../constants";
import { GET_PAGINATED_ORDERS } from "../../constants/queryKeys";
import CustomPageHeader from "../../shared/PageHeader";
import MobileViewOrderList from "./MobileViewOrderList";
import OrdersList from "./OrdersList";
import moment from "moment";

const today = moment();

export const OrderContext = createContext(null);

const CLV = () => {
  const { TabPane } = Tabs;

  const { isMobileView } = useAuth();

  const userSearch = useRef();
  const shopSearch = useRef();

  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [orderStatus, setOrderStatus] = useState("");
  const [orders, setOrders] = useState([]);

  const [addressRoute, setAddressRoute] = useState(null);

  const [cities, setCities] = useState(null);
  const [areas, setAreas] = useState(null);
  const [provinces, setProvinces] = useState(null);

  const [categories, setCategories] = useState(null);
  const [brands, setBrands] = useState(null);
  const [products, setProducts] = useState(null);
  const [timerange, setTimeRange] = useState("");

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, today]);
  const [saveFilterOpen, setSaveFilterOpen] = useState(false);
  const [filterName, setFilterName] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");

  const [filterState, setFilterState] = useState({
    filter_name: null,
    filter_data: {
      cities: null,
      areas: null,
      provinces: null,
      categories: null,
      brands: null,
      products: null,
      time_range: "",
      start_date: null,
      end_date: null,
      username: null,
      shop: null,
      status: null,
    },
  });

  const [sortObj, setSortObj] = useState({
    sortType: {
      created_at: false,
      total_paid_amount: false,
    },
    sort: ["-created_at"],
  });

  const updateFilterState = () => {
    setFilterState((prevState) => ({
      ...prevState,
      filter_name: filterName,
      filter_data: {
        cities,
        areas,
        provinces,
        categories,
        brands,
        products,
        time_range: timerange,
        start_date: startDate,
        end_date: endDate,
        username: userSearch.current,
        shop: shopSearch.current,
        status: orderStatus,
      },
    }));
  };

  const {
    data,
    status,
    isRefetching,
    refetch: refetchOrders,
  } = useQuery({
    queryFn: () =>
      getfilteredorders({
        page,
        size: pageSize,
        province: provinces,
        city: cities,
        area: areas,
        category: categories,
        brand: brands,
        product: products,
        time_range: timerange,
        start_date: startDate,
        end_date: endDate,
        username: userSearch.current,
        shop: shopSearch.current,
        status: orderStatus,
      }),
  });
  const { data: addressRoutes } = useQuery({
    queryFn: getAddressRoute,
    queryKey: ["ADDRESS_ROUTE"],
  });

  const { data: addressMetadatas } = useQuery({
    queryFn: getAddresses,
    queryKey: ["ADDRESS_META"],
  });

  const { data: allCategories } = useQuery({
    queryFn: getAllCategories,
    queryKey: ["ALL_CATEGORIES"],
  });

  const { data: allBrands } = useQuery({
    queryFn: getAllBrands,
    queryKey: ["ALL_BRANDS"],
  });

  const { data: allProducts } = useQuery({
    queryFn: getAllProducts,
    queryKey: ["ALL_PRODUCTS"],
  });

  const { data: allFilters, refetch: refetchFilters } = useQuery({
    queryFn: getFilters,
    queryKey: ["ALL_FILTERS"],
  });

  console.log("Here is the selected filter", selectedFilter);
  useEffect(() => {
    setOrders([]);
    if (data) setOrders((prev) => uniqBy([...prev, ...data.results], "id"));
  }, [data]);

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      setStartDate(dateRange[0].format("YYYY-MM-DD"));
      setEndDate(dateRange[1].format("YYYY-MM-DD"));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [dateRange]);

  useEffect(() => {
    updateFilterState();
  }, [
    cities,
    areas,
    provinces,
    categories,
    brands,
    products,
    timerange,
    startDate,
    endDate,
    userSearch.current,
    shopSearch.current,
    orderStatus,
    filterName,
  ]);

  useEffect(() => {
    refetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderStatus,
    sortObj,
    pageSize,
    addressRoute,
    provinces,
    areas,
    cities,
    categories,
    brands,
    products,
    timerange,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    refetchFilters();
  }, [filterState]);

  console.log("Here is all Filters", allFilters);

  useEffect(() => {
    addressMetadatas?.forEach((province) => {
      setProvinceList((prev) => uniqBy([...prev, province.name]));

      province.cities?.forEach((city) => {
        setCityList((prev) => uniqBy([...prev, city.name]));

        city.areas?.forEach((area) => {
          setAreaList((prev) => uniqBy([...prev, area.name]));
        });
      });
    });
  }, [addressMetadatas]);

  // useEffect(() => {
  //   if (provinces && provinces.length) {
  //     console.log("selected provinces", provinces);
  //     addressMetadatas?.forEach((province) => {
  //       if (provinces.includes(province.name)) {
  //         console.log("Here is the province", province.name);
  //
  //
  //       }
  //     });
  //   }
  // }, [provinces, cities, areas]);
  //
  //

  return (
    <div>
      <CustomPageHeader title="CLV" isBasicHeader />

      <OrderContext.Provider
        value={{
          dataSource: orders,
          ordersCount: data?.count,
          page,
          pageSize,
          refetchOrders,
          userSearch,
          shopSearch,
          setPage,
          setPageSize,
          sortObj,
          setSortObj,
          status: isRefetching ? "loading" : status,
          addressRoutes,
          setAddressRoute,
          addressMetadatas,
          cities,
          areas,
          provinces,
          setCities,
          setAreas,
          setProvinces,
          provinceList,
          cityList,
          areaList,
          categories,
          allCategories,
          setCategories,
          brands,
          allBrands,
          setBrands,
          products,
          allProducts,
          setProducts,
          timerange,
          setTimeRange,
          dateRange,
          setDateRange,
          orderStatus,
          setOrderStatus,
          filterName,
          setFilterName,
          saveFilterOpen,
          setSaveFilterOpen,
          filterState,
          allFilters,
          refetchFilters,
          selectedFilter,
          setSelectedFilter,
        }}
      >
        {isMobileView ? <MobileViewOrderList /> : <OrdersList />}
      </OrderContext.Provider>
    </div>
  );
};

export default CLV;
