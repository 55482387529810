import { useState, useContext, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Table, Tag, Space, Input, Select } from "antd";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { useAuth } from "../../../AuthProvider";
import CustomPageHeader from "../../../shared/PageHeader";
import { SearchOutlined } from "@ant-design/icons";
import { uniqBy, isEmpty } from "lodash";
import { GET_PAGINATED_PRODUCT_SKUS } from "../../../constants/queryKeys";
import { getPaginatedProdctSkus } from "../../../api/products/productSku";
import HistoryPage from "./HistoryPage";
import * as excel from "exceljs";
import { saveAs } from "file-saver";

const PriceHistory = () => {
  const { isMobileView } = useAuth();

  const { search } = useLocation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [productSkus, setProductSkus] = useState([]);
  const [productName, setProductName] = useState("");
  const [historyPageVisible, setHistoryPageVisible] = useState(false);
  const [priceHistory, setPriceHistory] = useState([]);
  const [priceType, setPriceType] = useState("");
  const [sortObj, setSortObj] = useState({
    sortType: {
      name: false,
      published_at: false,
    },
    sort: [],
  });
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedPriceType, setSelectedPriceType] = useState("");

  const searchInput = useRef(new URLSearchParams(search).get("search"));
  let timeout = 0;

  const {
    data,
    status,
    refetch: refetchProductSkus,
    isRefetching,
  } = useQuery(
    [
      GET_PAGINATED_PRODUCT_SKUS,
      page.toString() + pageSize.toString(),
      sortObj.sort,
    ],
    () =>
      getPaginatedProdctSkus(page, pageSize, sortObj.sort, searchInput.current)
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!isRefetching && status === "success" && data) {
      setProductSkus([]);
      setProductSkus((prev) => uniqBy([...prev, ...data.results], "slug"));
    }
  }, [data, isRefetching, status]);

  useEffect(() => {
    refetchProductSkus();
  }, [page, pageSize]);

  const closeHistoryPage = () => {
    setHistoryPageVisible(false);
  };
  const sortingFn = (header, name) =>
    setSortObj({
      sortType: {
        ...sortObj.sortType,
        [name]: !sortObj.sortType[name],
      },
      sort: [`${sortObj.sortType[name] ? "" : "-"}${header.dataIndex}`],
    });

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      width: "15%",
      render: (_, { name, product_sku_image, slug }) => (
        <div
          className="flex items-center gap-3 cursor-pointer text-blue-500 hover:underline"
          onClick={() => {
            navigate(
              "/product-sku/" + slug + `?search=${searchInput.current ?? ""}`
            );
          }}
        >
          <img
            alt=""
            className="h-[40px] w-[40px] object-cover rounded"
            src={product_sku_image?.thumbnail || "/rasan-default.png"}
          />
          <span>{name}</span>
        </div>
      ),
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "name"),
        };
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "quantity"),
        };
      },
    },
    {
      title: "CP Per Piece",
      dataIndex: "cost_price_per_piece",
      render: (_, { name, cost_price_per_piece, price_history_cppp }) => (
        <div
          className="cursor-pointer hover:underline"
          onClick={() => {
            setPriceHistory([]);
            setProductName(name);
            setPriceType("CP Per Piece History");
            setPriceHistory(price_history_cppp);
            setHistoryPageVisible(true);
          }}
        >
          <span>{cost_price_per_piece}</span>
        </div>
      ),
    },
    {
      title: "MRP Per Piece",
      dataIndex: "mrp_per_piece",
      render: (_, { name, mrp_per_piece, price_history_mpp }) => (
        <div
          className="cursor-pointer hover:underline"
          onClick={() => {
            setPriceHistory([]);
            setProductName(name);
            setPriceType("MRP Per Piece History");
            setPriceHistory(price_history_mpp);
            setHistoryPageVisible(true);
          }}
        >
          <span>{mrp_per_piece}</span>
        </div>
      ),
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "mrp_per_piece"),
        };
      },
    },
    {
      title: "SP Per Piece",
      dataIndex: "price_per_piece",
      render: (_, { name, price_per_piece, price_history_ppp }) => (
        <div
          className="cursor-pointer hover:underline"
          onClick={() => {
            setPriceHistory([]);
            setProductName(name);
            setPriceType("SP Per Piece History");
            setPriceHistory(price_history_ppp);
            setHistoryPageVisible(true);
          }}
        >
          <span>{price_per_piece}</span>
        </div>
      ),
      sorter: true,
      onHeaderCell: (header) => {
        return {
          onClick: () => sortingFn(header, "price_per_piece"),
        };
      },
    },
  ];

  const exportToExcel = () => {
    const workbook = new excel.Workbook();
    const worksheet = workbook.addWorksheet("Price History");

    const startDate = new Date(2024, 0, 1);
    const englishDays = Array.from({ length: 365 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date.toISOString().split("T")[0];
    });

    const headers = [
      { header: "S.N", key: "sn", width: 10 },
      { header: "Product Name", key: "productName", width: 30 },
      { header: "Unit", key: "unit", width: 10 },
      ...englishDays.map((day) => ({ header: day, key: day, width: 10 })),
    ];

    worksheet.columns = headers;

    selectedProduct.forEach((product, index) => {
      let priceHistories = [];
      if (selectedPriceType === "cppp") {
        priceHistories = product.price_history_cppp;
      } else if (selectedPriceType === "ppp") {
        priceHistories = product.price_history_ppp;
      } else if (selectedPriceType === "mpp") {
        priceHistories = product.price_history_mpp;
      }

      const priceData = {};
      let lastPrice = null;
      englishDays.forEach((day) => {
        const history = priceHistories.find(
          (ph) => new Date(ph.timestamp).toISOString().split("T")[0] === day
        );
        if (history) {
          lastPrice = history.new_price;
        }
        priceData[day] = lastPrice || "";
      });

      // Add product data
      // priceHistories.forEach((history) => {
      //   const date = new Date(history.timestamp).toISOString().split("T")[0];
      //   priceData[date] = history.new_price;
      //   // worksheet.addRow({
      //   //   sn: product.sn,
      //   //   productName: product.name,
      //   //   unit: product.quantity,
      //   //   date: new Date(history.timestamp).toLocaleDateString(),
      //   //   price: history.new_price,
      //   // });
      // });

      const row = {
        sn: product.sn,
        productName: product.name,
        unit: product.quantity,
        ...priceData,
      };

      worksheet.addRow(row);
    });

    // Save to file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `price-history-${priceType}.xlsx`
      );
    });
  };

  const rowSelection = {
    selectedRowKeys: checkedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedRows(selectedRowKeys);
      setSelectedProduct(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
  };

  return (
    <>
      <CustomPageHeader title="Price History" isBasicHeader />
      <>
        <div className="flex flex-col bg-white p-6 rounded-[8.6333px] min-h-[70vh]">
          <div className="flex sm:flex-row flex-col-reverse sm:items-center items-start justify-between gap-2 mb-3">
            <div className="py-[3px] px-3 min-w-[18rem] border-[1px] border-[#D9D9D9] rounded-lg flex items-center justify-between">
              <SearchOutlined style={{ color: "#D9D9D9" }} />
              <Input
                bordered={false}
                className="!p-0 ml-1 w-full placeholder:text-[#D9D9D9]"
                defaultValue={searchInput.current}
                placeholder={"Search product..."}
                type="text"
                allowClear
                onChange={(e) => {
                  searchInput.current = e.target.value;
                  if (timeout) clearTimeout(timeout);
                  timeout = setTimeout(() => {
                    setPage(1);
                    refetchProductSkus();
                  }, 400);
                }}
              />
            </div>
            {selectedProduct.length > 0 && (
              <Select
                className="!w-72"
                // defaultValue="cppp"
                style={{ width: 120 }}
                onChange={(value) => setSelectedPriceType(value)}
                options={[
                  { value: "cppp", label: "CP Per Piece" },
                  { value: "mpp", label: "MRP Per Piece" },
                  { value: "ppp", label: "SP Per Piece" },
                ]}
                placeholder="Choose Price Type to Export"
              />
            )}
            {selectedProduct.length > 0 && selectedPriceType && (
              <Button onClick={exportToExcel}> Export Price History</Button>
            )}
            <span style={{ fontSize: "10px" }}>
              * Click on the individual price to see Price History
            </span>
          </div>

          <div className="flex-1">
            <Table
              columns={
                JSON.parse(localStorage.getItem("groups") || "[]")?.[0]
                  ?.name === "superadmin"
                  ? columns
                  : columns.filter(
                      (column) => column.dataIndex !== "cost_price_per_piece"
                    )
              }
              dataSource={
                productSkus?.map((item) => ({
                  ...item,
                  key: item.slug,
                })) || []
              }
              loading={status === "loading" || isRefetching}
              rowSelection={{ ...rowSelection }}
              pagination={{
                showSizeChanger: true,
                pageSize,
                total: data?.count,
                current: page,

                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
              }}
              scroll={{
                x: isEmpty(productSkus) && !isMobileView ? null : 1000,
              }}
              showSorterTooltip={false}
            />
          </div>
        </div>
      </>
      {historyPageVisible && (
        <HistoryPage
          show={historyPageVisible}
          onClose={closeHistoryPage}
          data={priceHistory}
          name={productName}
          type={priceType}
        />
      )}
    </>
  );
};

export default PriceHistory;
