import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { getTopFiveOrders } from "../../../api/orders";
import { useNavigate } from "react-router-dom";
import getOrderStatusColor from "../../../shared/tagColor";
import { capitalize } from "lodash";
import moment from "moment";

const TopFiveOrders = ({ user_phone }) => {
  const [topFiveOrders, setTopFiveOrders] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTopFiveOrders({ user_phone });
        setTopFiveOrders(data.data.results);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [user_phone]);

  const columns = [
    {
      key: "orderId",
      title: "Order ID",
      dataIndex: "id",
      render: (_, { id, status }) => {
        return (
          <div
            className="text-blue-500 cursor-pointer horver:underline"
            onClick={() => navigate(`/orders/view-order/${id}`)}
          >
            #{id}
          </div>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "phone",
      key: "phone",
      width: "25%",
      render: (_, { phone, customer_name, id }) => {
        return (
          <div
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => navigate(`/orders/view-order/${id}`)}
          >
            {customer_name ? `${customer_name} (${phone})` : phone}
          </div>
        );
      },
    },
    {
      title: "Total Paid Amount",
      dataIndex: "total_paid_amount",
      key: "total_paid_amount",
      render: (_, { total_paid_amount }) => {
        return <>Rs. {total_paid_amount}</>;
      },
    },
    {
      title: "Delivery Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return (
          <>
            <Tag color={getOrderStatusColor(status)}>
              {status.toUpperCase().replaceAll("_", " ")}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Shop Name",
      dataIndex: "shop_name",
      key: "shop_name",
      width: "15%",
      render: (_, { shop_name }) => (
        <span className="w-16" style={{ overflowWrap: "anywhere" }}>
          {capitalize(shop_name?.replaceAll("_", " "))}
        </span>
      ),
    },
    {
      title: "Ordered At",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => {
        return <>{moment(created_at).format("lll")}</>;
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={topFiveOrders?.map((data) => ({
        ...data,
        key: data.id,
        count: data.count,
        orderId: data.id,
      }))}
    />
  );
};

export default TopFiveOrders;
